@font-face {
font-family: 'font';
src: url(/_next/static/media/f88d3791fa26297c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/355aa0f0c1ca250c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/6c22328a975aecf7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/51a1b75a700a28cd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'font';
src: url(/_next/static/media/98fc60cfd92a3dc3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'font Fallback';src: local("Arial");ascent-override: 89.66%;descent-override: 39.85%;line-gap-override: 0.00%;size-adjust: 100.38%
}.__className_4e5792 {font-family: 'font', 'font Fallback'
}.__variable_4e5792 {--main-font: 'font', 'font Fallback'
}

@font-face {
font-family: 'faIcon';
src: url(/_next/static/media/a470e14e6b64eea7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'faIcon';
src: url(/_next/static/media/e9fa290082dff566-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: 'faIcon';
src: url(/_next/static/media/d897b25edee26941-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'faIcon Fallback';src: local("Arial");ascent-override: 89.84%;descent-override: 14.45%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_9395c9 {font-family: 'faIcon', 'faIcon Fallback'
}.__variable_9395c9 {--fa-icon: 'faIcon', 'faIcon Fallback'
}

